import { atom } from "jotai";

export const generatedMockupsAtom = atom<IGeneratedMockupAtom>({});

export interface IGeneratedMockup {
    id: number;
    brand_id: number;
    mockup_url: string;
    product_id: number;
    mockup_id: number;
}

export interface IGeneratedMockupAtom {
    [key: string]: IGeneratedMockup[];
}

export interface IGetGeneratedMockupRequest {
    brand_id: number;
    product_id: number;
}