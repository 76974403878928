import { useAtom, useSetAtom } from "jotai";
import axios, { AxiosResponse } from 'axios';
import { environment } from "../../environment";
import { errorCatcher, snackbarSettingsAtom } from "../../hooks";
import { PaginatedResponseType } from "../../globals/types";
import { 
    IJDMLType, 
    IDropperGroup, 
    IDropGroup, 
    IGetDropGroupsRequest, 
    IPaginatedDropGroup, 
    jdmlAtom, 
    dropperGroupLoadingAtom, 
    dropperGroupAtom, 
    dropGroupsAtom, 
    dropGroupsLoadingAtom 
} from "./types";
import { IUser, useLogin } from "../login";
import { IOrder } from "../order";
import { IOrderLineItemDTO } from "../scanner";

type setJDMLType = (payload: IJDMLType[]) => void;
type FetchJDMLsType = () => void;
type GetJDMLDropperGroup = (brand_id: number, material_id: number) => Promise<IDropperGroup | null>;
type InsertJDMLDropGroup = (drop_group: IDropGroup) => Promise<IDropGroup | null>;
type DeleteJDMLDropGroup = (id: number) => Promise<boolean>;
type GetJDMLDropGroups = (request: IGetDropGroupsRequest) => Promise<PaginatedResponseType<IPaginatedDropGroup>>;
type RegenerateJDMLType = (order_id: number, lineItem?: IOrderLineItemDTO | null) => Promise<IOrder | null>;

export type JDMLKeyFunctions = {
    get: FetchJDMLsType;
    getDropperGroup: GetJDMLDropperGroup;
    insertDropGroup: InsertJDMLDropGroup;
    deleteDropGroup: DeleteJDMLDropGroup;
    getDropGroups: GetJDMLDropGroups;
    regenerateJDML: RegenerateJDMLType;
}

type UseJDMLs = [
    IJDMLType[],
    setJDMLType,
    IDropperGroup | null,
    PaginatedResponseType<IPaginatedDropGroup>,
    JDMLKeyFunctions
];

export const useJDMLs = (): UseJDMLs => {
    const [jdmls, setJDMLs] = useAtom(jdmlAtom);
    const [user, authToken, loginFunctions] = useLogin();

    const setSnackbar = useSetAtom(snackbarSettingsAtom);
    const setDropperGroupLoading = useSetAtom(dropperGroupLoadingAtom);
    const [dropperGroup, setDropperGroup] = useAtom(dropperGroupAtom);

    const [dropGroups, setDropGroups] = useAtom(dropGroupsAtom);
    const setDropGroupsLoading = useSetAtom(dropGroupsLoadingAtom);

    const getJDMLs = () => {
        const fetchJDMLs = async (setter: setJDMLType): Promise<void> => {
            const results = await axios.get(environment.jdmlUrl, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

            if (results) {
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting the JDML.'
                })
            }
        }

        fetchJDMLs(setJDMLs);
    }

    const getDropperGroup: GetJDMLDropperGroup = async (brand_id: number, material_id: number): Promise<IDropperGroup | null> => {
        setDropperGroupLoading(true);
        const dropperGroup: AxiosResponse<IDropperGroup> | void = await axios.get(`${environment.jdmlUrl}/dropper/group?brand_id=${brand_id}&material_id=${material_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (dropperGroup) {
            setDropperGroup(dropperGroup.data);
            setDropperGroupLoading(false);
            return dropperGroup.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Failed to get jdml dropper group'
            });
            setDropperGroup(null);
            setDropperGroupLoading(false);
            return null;
        }
    }

    const getDropGroups: GetJDMLDropGroups = async (request: IGetDropGroupsRequest): Promise<PaginatedResponseType<IPaginatedDropGroup>> => {
        setDropGroupsLoading(true);
        const dropperGroups: AxiosResponse<PaginatedResponseType<IPaginatedDropGroup>> | void = await axios.get(`${environment.jdmlUrl}/dropper/groups?page_count=${request.page_count}&page=${request.page}${request.queryString === '' || !request.queryString ? `` : `&queryString=${ encodeURIComponent(request.queryString)}`}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (dropperGroups) {
            setDropGroups(dropperGroups.data);
            setDropGroupsLoading(false);
            return dropperGroups.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Failed to get drop groups'
            });
            setDropGroups({ results: [], count: 0, hasBefore: false, hasNext: false });
            setDropGroupsLoading(false);
            return { results: [], count: 0, hasBefore: false, hasNext: false };
        }
    }

    const insertDropGroup: InsertJDMLDropGroup = async (drop_group: IDropGroup): Promise<IDropGroup | null> => {
        const dropperGroups: AxiosResponse<IDropGroup> | void = await axios.post(`${environment.jdmlUrl}/dropper/group`, drop_group, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (dropperGroups) {
            setDropGroups({
                results: [...dropGroups.results, {
                    user_id: (user as IUser).id as number, 
                    user_first: (user as IUser).first, 
                    user_last: (user as IUser).last,
                    created_at: dropperGroups.data.created_at as string,
                    id: dropperGroups.data.id as number,
                    line_items: dropperGroups.data.line_items,
                    material_name: '',
                    brand_id: drop_group.brand_id,
                    brand_name: ''
                }],
                count: dropGroups.results.length + 1,
                hasBefore: dropGroups.hasBefore,
                hasNext: dropGroups.hasNext
            });
            return dropperGroups.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Failed to insert drop group'
            });
            return null;
        }
    }

    const deleteDropGroup: DeleteJDMLDropGroup = async (id: number): Promise<boolean> => {
        const result: AxiosResponse<boolean> | void = await axios.delete(`${environment.jdmlUrl}/dropper/group?id=${id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (result) {
            setDropGroups({
                count: dropGroups.count - 1,
                hasBefore: dropGroups.hasBefore,
                hasNext: dropGroups.hasNext,
                results: dropGroups.results.filter((dropGroup: IPaginatedDropGroup) => {
                    if (dropGroup.id === id) return false;
                    return true;
                })
            });
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Successfully deleted drop group'
            });
            return true;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Error while deleting drop group'
            });
            return false;
        }
    }

    const regenerateJDML: RegenerateJDMLType = async  (order_id: number, lineItem: IOrderLineItemDTO | null = null): Promise<IOrder | null> => {
        const result: AxiosResponse<IOrder> | void = await axios.post(`${environment.ordersUrl}/regenerate-jdml`, { order_id, line_item: lineItem }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (result) {
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'JDML Regenerated Successfully'
            });
            return result.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Error while regenerating JDML.'
            });
            return null;
        }
    }

    const keyFunctions: JDMLKeyFunctions = {
        get: getJDMLs,
        getDropperGroup,
        getDropGroups,
        insertDropGroup,
        deleteDropGroup,
        regenerateJDML
    }

    return [
        jdmls,
        setJDMLs,
        dropperGroup,
        dropGroups,
        keyFunctions
    ]
}