import { useAtom, useSetAtom } from "jotai";
import Axios from 'axios';
import { environment } from "../../environment";
import { errorCatcher, snackbarSettingsAtom } from "../../hooks";
import { useLogin } from "../login";
import { artTypeAtom, IArtType } from "./types";


type SetArtTypeType = (payload: IArtType[]) => void;

type FetchArtTypesType = () => void;

type ArtTypeKeyFunctions = {
    get: FetchArtTypesType;
}

type UseArtTypes = [
    IArtType[],
    SetArtTypeType,
    ArtTypeKeyFunctions
];

export const useArtTypes = (): UseArtTypes => {
    const [artTypes, setArtTypes] = useAtom(artTypeAtom);
    const setSnackbar = useSetAtom(snackbarSettingsAtom);
    const [,authToken, loginFunctions] = useLogin();

    const getArtTypes = () => {
        const fetchArtTypes = async (setter: SetArtTypeType): Promise<void> => {
            const results = await Axios.get(environment.artTypeUrl, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (results) {
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while fetching art types. Please try again later.'
                })
            }
        }

        fetchArtTypes(setArtTypes);
    }

    const keyFunctions: ArtTypeKeyFunctions = {
        get: getArtTypes
    }

    return [
        artTypes,
        setArtTypes,
        keyFunctions
    ]
}