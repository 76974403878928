import React from 'react';
import { WallmatesFooter } from '../../images/wallmates-footer';
import { Button, Field, Input, InputError, Label, Link, Spinner } from '../../components/UI';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetTitle, useFeatureFlags, useForm } from '../../hooks';
import { useLogin, ILoginRequest } from '../../services';

const bucket = process.env.REACT_APP_AWS_BUCKET_IMAGES || '';

const imageLinks = [
    `https://${bucket}.s3.us-west-1.amazonaws.com/dashboard-images/4905311699007.jpg`
]

const Login: React.FunctionComponent<{}> = (): JSX.Element => {

    const setTitle = useSetTitle();

    const [imageNumber, _setImageNumber] = React.useState(0);
    const imageNumberRef = React.useRef(imageNumber);
    const setImageNumber = (value: number) => {
        _setImageNumber(value);
        imageNumberRef.current = value;
    }

    const [loading, setLoading] = React.useState<boolean>(false);
    const [showCreateAccount, setShowCreateAccount] = React.useState<boolean>(false);
    const [FeatureFlagFunctions] = useFeatureFlags();
    const [user,, loginFunctions] = useLogin();

    const location = useLocation();
    const navigate = useNavigate();

    const form = useForm([
        {
            key: 'email',
            validation: (value: string) => {
                if (value === '') return 'Email is required';
                return null;
            }
        }, 
        {
            key: 'password',
            validation: (value: string) => {
                if (value === '') return 'password is required';
                return null;
            }
        }
    ]);

    React.useEffect(() => {
        setTitle("Login");
        setInterval(() => {
            const nextImage = imageNumberRef.current + 1;
            setImageNumber(nextImage >= 5 ? 0 : nextImage);
        }, 10000);
        checkFeatureFlags();
    }, []);

    React.useEffect(() => {
        if (user !== null) {
            const queryParams = location.search.replace('?', '').split('&');
            const ref = queryParams.find((value: string) => value.includes('ref='));
            if (ref) {
                const path = decodeURIComponent(ref.replace('ref=', ''));
                navigate(path);
            } else {
                navigate('/');
            }
        }
    }, [user]);

    const checkFeatureFlags = async () => {
        const showCreateAccount = await FeatureFlagFunctions.get('show-create-account');
        setShowCreateAccount(showCreateAccount);
    }

    const callLogin = async () => {
        setLoading(true);
        const loginRequest: ILoginRequest = {
            password: form.valueAtKey('password'),
            email: form.valueAtKey('email')
        }

        await loginFunctions.login(loginRequest);

        setLoading(false);
    }

    return (
        <div className="w-screen h-screen relative">
            <div className="absolute t-0 l-0 w-full h-full z-0">
                <img 
                    src={imageLinks[0]}
                    className="w-full h-full"
                />
            </div>
            <div className="absolute t-0 l-0 w-full h-full z-20 bg-black/30"></div>
            <div className="absolute t-0 l-0 w-full h-full z-30 bg-black/5"></div>
            <div className="absolute t-0 l-0 w-full h-full z-40">
                <div className="flex flex-row justify-center items-center w-full h-full">
                    <div className="w-full md:w-1/2 lg:w-2/3 xl:w-1/3 h-full md:h-auto lg:h-auto bg-slate-50 dark:bg-slate-800 shadow-lg border-2 border-slate-400 rounded-lg min-w-64">
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col justify-center items-center">
                                <WallmatesFooter height={100} />
                                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                                    Sign in to your account
                                </h2>
                            </div>

                            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                <form className="space-y-6" >
                                    <div>
                                        <Field>
                                            <Label>Email</Label>
                                            <Input type="text" onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                    form.setValueAtKey('email', ev.target.value)
                                                }}
                                            ></Input>
                                            <InputError message={form.errors['email']}></InputError>
                                        </Field>
                                    </div>

                                    <div>
                                        <Field>
                                            <Label>Password</Label>
                                            <Input type="password" onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                    form.setValueAtKey('password', ev.target.value)
                                                }}
                                            ></Input>
                                            <InputError message={form.errors['password']}></InputError>
                                        </Field>
                                    </div>

                                    <div className="w-full flex flex-col">
                                        <Button
                                            onClick={() => {
                                                callLogin()
                                            }}
                                            color={'emerald'}
                                        >
                                            Sign In
                                        </Button>
                                    </div>
                                    <div className='flex justify-between text-gray-900 dark:text-white'>
                                        {
                                            showCreateAccount && <Link href='/new-account' className='cursor-pointer !font-bold'>Create Account</Link>
                                        }
                                        <Link href='/forgot-password' className='cursor-pointer !font-bold'>Forgot Password?</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {   loading ?
                <div className="absolute t-0 l-0 w-full h-full z-50 bg-black/30">
                    <div className="flex flex-col justify-center w-full h-full items-center">
                        <Spinner show={true} size={200} />
                    </div>
                </div> :
                null
            }
        </div>
    )
}

export default Login;