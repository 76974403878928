import { atom } from "jotai";

export const translationsAtom = atom<{[key: string]: string}>({});
export const translationsLoadingAtom = atom<boolean>(false);
export const translationKeysAtom = atom<ITranslationKey[]>([]);
export const translationLanguageAtom = atom<ITranslationLanguage[]>([]);

export interface ITranslationLanguage {
    id?: number;
    name: string;
    language_key: string;
    language_image: string;
}

export interface ITranslationKey {
    id?: number;
    key_name: string;
}

export interface ITranslation {
    id?: number;
    translation_key_id: number;
    language_id: number;
    translation_text: string;    
}

export interface IInsertTranslationRequest {
    translation_key_text: string,
    translations: {
        [key: string]: string
    }
}

export interface IUpdateTranslationRequest {
    translation_key: ITranslationKey;
    translations: ITranslation[];
}