import axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks/useSnackbar";
import { useLogin } from "../login/login";
import { 
    IPrinter, 
    ILineItemIssues, 
    ISubmitLineItemIssueRequest, 
    printerAtom, 
    selectedPrinterAtom, 
    getPrintersLoadingAtom, 
    lineItemIssuesAtom 
} from "./types";
import { IScanResponse, currentScanItemAtom, scanLoadingAtom } from "../scanner";

type GetPrinters = () => Promise<IPrinter[]>;
type GetIssues = () => Promise<ILineItemIssues[]>;
type FlagItem = (body: ISubmitLineItemIssueRequest) => Promise<IScanResponse | null>;

type PrinterKeyFunctions = {
    get: GetPrinters,
    getIssues: GetIssues,
    submitFlag: FlagItem
}

type UsePrinters = [
    IPrinter[],
    ILineItemIssues[],
    PrinterKeyFunctions
]

export const usePrinters = (): UsePrinters => {
    const [printers, setPrinters] = useAtom(printerAtom);
    const setSelectedPrinter = useSetAtom(selectedPrinterAtom);
    const setPrintersLoading = useSetAtom(getPrintersLoadingAtom);

    const setScan = useSetAtom(currentScanItemAtom);
    const setScanLoading = useSetAtom(scanLoadingAtom);

    const [issues, setIssues] = useAtom(lineItemIssuesAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getPrinters = async (): Promise<IPrinter[]> => {
        setPrintersLoading(true);
        const getPrinters: AxiosResponse<IPrinter[]> | void = await axios.get(`${environment.printerUrl}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (getPrinters) {
            setPrinters(getPrinters.data);
            const previousPrinter = localStorage.getItem('printer');
            if (previousPrinter) {
                const p = JSON.parse(previousPrinter);
                setSelectedPrinter(p);
            } else {
                setSelectedPrinter(getPrinters.data[0]);
                localStorage.setItem('printer', JSON.stringify(getPrinters.data[0]));
            }
            setPrintersLoading(false);
            return getPrinters.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving printers. Please try again later.'
            });
            setPrintersLoading(false);
            return [];
        }
    }

    const getIssues = async (): Promise<ILineItemIssues[]> => {
        setPrintersLoading(true);
        const getIssues: AxiosResponse<ILineItemIssues[]> | void = await axios.get(`${environment.printerUrl}/line-item-issues`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (getIssues) {
            setIssues(getIssues.data);
            setPrintersLoading(false);
            return getIssues.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error retrieving line item issues. Please try again later.'
            });
            setPrintersLoading(false);
            return [];
        }
    }

    const flagItem = async (body: ISubmitLineItemIssueRequest): Promise<IScanResponse | null> => {
        setScanLoading(true);
        const postIssue: AxiosResponse<IScanResponse> | void = await axios.post(`${environment.printerUrl}/line-item-issues`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (postIssue) {
            setScan(postIssue.data);
            setScanLoading(false);
            return postIssue.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error flagging line item'
            });
            setScanLoading(false);
            return null;
        }
    }

    const keyFunctions: PrinterKeyFunctions = {
        get: getPrinters,
        getIssues,
        submitFlag: flagItem
    }

    return [
        printers,
        issues,
        keyFunctions
    ]
}