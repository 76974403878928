import axios, { AxiosResponse } from "axios";
import { errorCatcher, useSnackbar } from "./useSnackbar";
import { environment } from "../environment";
import { useLogin } from "../services";

export interface FeatureFlag {
    id: number;
    flag_name: string;
    production_value: boolean;
    is_active: boolean;
    development_value: boolean;
}

type GetFeatureFlag = (value: string) => Promise<boolean>;

type FeatureFlagFunctions = {
    get: GetFeatureFlag
}

type UseFeatureFlags = [
    FeatureFlagFunctions
]

export const useFeatureFlags = (): UseFeatureFlags => {
    const [,authToken, loginFunctions] = useLogin();
    const [,setSnackbar] = useSnackbar();

    const getFlag: GetFeatureFlag = async (value: string): Promise<boolean> => {
        const flags: AxiosResponse<FeatureFlag[]> | void = await axios.get(`${environment.authenticationUrl}/feature-flags`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (flags) {
            const flag = flags.data.find((flag: FeatureFlag) => flag.flag_name === value);
            if (flag) {
                if (process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'test') return flag.development_value;
                return flag.production_value;
            } else {
                console.error(`Feature Flag: ${value} does not exist.`);
                return false;
            }
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Failed to get feature flags'
            });
            return false;
        }
    }

    return [
        {
            get: getFlag
        }
    ]
}