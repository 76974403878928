import React from 'react';
import { Button, Spinner, Text } from '../../UI';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { useNotifications, notificationsLoadingAtom, INotification, IApplyPatternRequest, ICreateDesignRequest, ICreateSampleRequest, ICreateHeaderRequest } from '../../../services';

export interface NotificationsProps {
    isOpen: boolean;
}

export type NotificationsType = NotificationsProps & React.ComponentPropsWithoutRef<'div'>;

export const NotificationsWrapper: React.FunctionComponent<NotificationsType> = (props: NotificationsType): JSX.Element => {

    const { isOpen, className } = props;

    const [notifications, setNotifications, notificationFunctions] = useNotifications(1);
    const actionLoading = useAtomValue(notificationsLoadingAtom);

    const [timer, setTimer] = React.useState<NodeJS.Timer | null>(null);

    React.useEffect(() => {
        if (isOpen) {
            notificationFunctions.get();
            // const interval = setInterval(() => {
            //     notificationFunctions.get();
            // }, 20000);
            // setTimer(interval);
        } else {
            if (timer) {
                clearInterval(timer as NodeJS.Timer);
                setTimer(null);
            }
            setNotifications([]);
        }
    }, [isOpen]);

    const getNotificationColor = (notification: INotification): string => {
        switch (notification.status) {
            case 'failed': {
                return 'border-l-red-400 dark:border-l-red-600';
            }
            case 'processed': {
                return 'border-l-green-400 dark:border-l-green-600';
            }
            case 'processing': {
                return 'border-l-yellow-400 dark:border-l-yellow-600';
            }
            case 'unprocessed': {
                return 'border-l-gray-600 dark:border-l-grey-400';
            }
        }
    }

    const getNotificationMessage = (notification: INotification) => {
        let result = '';

        switch (notification.category) {
            case 'MOCKUP_UPLOAD': {
                result += '"Mockup Creation" ';
                break;
            }
            case 'PRODUCT_UPLOAD': {
                result += '"Product" ';
                break;
            }
            case 'SAMPLE_CREATION': {
                result += '"Sample Creation" ';
                break;
            }
            case 'HEADER_CREATION': {
                result += '"Header Creation" ';
                break;
            }
        }

        result += `${notification.status}`;

        return result;
    }

    const getNotificationInfo = (notification: INotification) => {
        let result = '';

        switch (notification.category) {
            case 'MOCKUP_UPLOAD': {
                const passthrough = notification.passthrough_data as IApplyPatternRequest;
                result += `store Product ID - ${passthrough.product_id}`;
                break;
            }
            case 'PRODUCT_UPLOAD': {
                const passthrough = notification.passthrough_data as ICreateDesignRequest;
                result += `store Product ID - ${passthrough.product_id}`;
                break;
            }
            case 'SAMPLE_CREATION': {
                const passthrough = notification.passthrough_data as ICreateSampleRequest;
                result += `design ID - ${passthrough.design_id}`;
                break;
            }
            case 'HEADER_CREATION': {
                const passthrough = notification.passthrough_data as ICreateHeaderRequest;
                result += `mockup ID - ${passthrough.generated_mockup_id}`;
                break;
            }
        }

        return result;
    }

    const sort = (a: INotification, b: INotification): number => {
        if (a.id > b.id) return -1;
        if (b.id > a.id) return 1;
        return 0;
    }

    return (
        <div
            className={clsx(className, 'overflow-hidden')}
            style={{height: 'calc(100vh - 80px)'}}
        >
            <div className="flex flex-col gap-y-2 overflow-auto h-full py-5 px-3">
                <div className="flex flex-row gap-2 justify-end">
                    {notifications.length !== 0 && actionLoading ? <Spinner show={true} /> : null}
                    <Button
                        onClick={() => {
                            notificationFunctions.get();
                        }}
                        color='transparent'
                        key={"RefreshButton"}
                    >
                        Refresh
                    </Button>
                </div>
                {
                    notifications.length === 0 ? (
                        actionLoading ? <Spinner show={true} /> : <Text>No Notifications To Show</Text>
                    ) : 
                    notifications.sort(sort).map((notification: INotification) => {
                        return <div 
                            key={notification.id as number} 
                            className={clsx(
                                'border-l-8 border-y border-r border-black dark:border-white',
                                getNotificationColor(notification),
                                'flex flex-col gap-x-2 justify-between p-4'
                            )}
                        >
                            <Text><b>ID:</b> { notification.id }</Text>
                            <Text><b>Status Type: </b>{ getNotificationMessage(notification) }</Text>
                            <Text><b>Info: </b>{ getNotificationInfo(notification) }</Text>
                            <Text><b>Message: </b>{ notification.status_message }</Text>
                        </div>
                    })
                }
            </div>
        </div>
    )
}