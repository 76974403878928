import { atom } from "jotai";
import { IOrderDTO } from "../order";
import { IUser } from "../login";
import { IPrinter } from "../printers";

export const currentWeightScanItemAtom = atom<IWeightScanResponse | null>(null);
export const weightScanItemLoadingAtom = atom<boolean>(false);
export const weighQueueAtom = atom<IWeighQueue[]>([]);
export const currentScanItemAtom = atom<IScanResponse | null>(null);
export const scanLoadingAtom = atom<boolean>(false);
export const lastScanValueAtom = atom<string>('');
export const currentShippingScanItemAtom = atom<IShippingScanResponse | null>(null);
export const shippingScanLoading = atom<boolean>(false);

export interface IScanResponse {
    order: IOrderDTO;
    zpl: string;
    lineItems: IOrderLineItemDTO[];
    orderConsumables: IOrderConsumables;
    scanValue: string;
}

export interface IOrderConsumables {
    id: number;
    created_at: string;
    order_id: number;
    small_box: number;
    large_box: number;
    paste: number;
    squeegee: number;
}

export interface IOrderLineItemDTO {
    id: number;
    print_range: string;
    line_item_jdml: string;
    design: {
        id: number;
        design_name: string;
        shopify_id: string;
        collection: {
            name: string;
        };
        pat_19_file: string;
        metadata: {
            id: number;
            material: {
                category: string;
            }
        }
        sample: string;
        tiled: string;
        twelvth: string;
        mockup: string;
        header: string;
    };
    consumable: {
        id: number;
        name: string;
        image: string;
        sku: string;
    };
    material: {
        id: number;
        category: string;
    };
    order_id: number;
    height: string;
    width: number;
    droppable: boolean;
    jdml_dropped: boolean;
    line_item_scans: IOrderLineItemScanDTO[];
    print_id: string;
    line_item_issues: IOrderLineItemIssueDTO[];
    line_item_reprints: IOrderLineItemReprintsDTO[];
    name?: string;
}

export interface IOrderLineItemReprintsDTO {
    id: number;
    scan_time: string;
    scanned: boolean;
    bin: string;
    reprint_jdml: string;
    created_at: string;
    range_start: number;
    range_end: number;
    print_id: string;
}

export interface IOrderLineItemIssueDTO {
    id: number;
    employee: {
        id: number;
        first: string;
        last: string;
    };
    issue: {
        id: number;
        value: string;
    };
    created_at: string;
}

export interface IOrderLineItemScanDTO {
    id: number;
    scan_time: string;
    scanned: boolean;
    split_index: number;
    bin: string;
    print_range_start: number;
    print_range_end: number;
}

export interface IScanRequest {
    scanValue: string;
    isUnscan: boolean;
    loadedOrder: number | null;
}

export interface IManualSearch {
    query: string;
}

export interface IWeightScanResponse {
    [key: string]: any;
}

export type QueueStatus = 'unprocessed' | 'processing' | 'processed';

export interface IWeighQueue {
    id?: number;
    created_at: string;
    box_weights: IBoxWeight;
    process_status: QueueStatus;
}

export interface IBoxWeight {
    id?: number;
    created_at: string;
    box_type: 'large' | 'medium' | 'small';
    weight_pounds: number;
    weight_ounces: number;
    order: {
        id: number;
        line_items: {
            id: number;
            scan: {
                id: number;
                scanned: boolean;
            }[]
        }[]
    }
    tag_text: string;
}

export interface IShippingBoxWeight {
    id?: number;
    created_at: string;
    box_type: 'large' | 'medium' | 'small';
    weight_pounds: number;
    weight_ounces: number;
    order_id: number;
    tag_text: string;
    scanned: boolean;
    scan_time: string;
    employee_id: IUser;
    box_group: number;
}

export interface IShippingScanResponse {
    boxWeights: IShippingBoxWeight[];
    order: IOrderDTO;
    shippingRates: IRates[];
    shipments: IShipment[];
}

export interface IShippingScanRequest {
    scanValue: string;
    employee: number;
    printer: IPrinter;
}

export interface IRates {
    box_group: number;
    group_weight: number;
    rates: IRate[];
    selectedRate: IShipmentCode;
    boxCount: 1 | 2;
}

export interface IRate {
    serviceName: string;
    serviceCode: string;
    shipmentCost: number;
    otherCost: number;
    shipmentCodes: IShipmentCode;
}

export interface IShipmentCode {
    carrierCode: CarrierCode;
    packageCode: PackageCode;
    serviceCode: ServiceCode;
    dimensions: DimensionGroup;
}

export type StampsPackageCode = 
    'package' | 
    'flat_rate_envelope' | 
    'flat_rate_legal_envelope' |
    'flat_rate_padded_envelope' | 
    'large_envelope_or_flat' | // SAMPLES ENVELOPE
    'large_flat_rate_box' |
    'large_package' | 
    'letter' |
    'medium_flat_rate_box' | // MEDIUM BOXES FOR PO BOXES
    'non_rectangular' |
    'regional_rate_box_a' |
    'regional_rate_box_b' |
    'small_flat_rate_box' | // SMALL BOXES FOR PO BOXES
    'thick_envelope';

export type StampsServiceCode =
    'usps_priority_mail' |
    'usps_priority_mail_express' |
    'usps_parcel_select' |
    'usps_first_class_mail' |
    'usps_media_mail' |
    'usps_ground_advantage' | // DOMESTIC SAMPLES AND ALL PO BOXES
    'usps_priority_mail_international' |
    'usps_priority_mail_express_international' |
    'usps_first_class_mail_international' |
    'globalpost_economy' |
    'globalpost_priority' |
    'gp_plus' |
    'globalpost_parcel_select_smart_saver';

export type FedexPackageCode =
    'package' |
    'fedex_envelope_onerate' |
    'fedex_extra_large_box_onerate' |
    'fedex_large_box_onerate' |
    'fedex_medium_box_onerate' | // THIS IS OUR FEDEX FLAT RATE PACKAGE CODE
    'fedex_pak_onerate' |
    'fedex_small_box_onerate' | // This is our fedex flat rate for the small boxes
    'fedex_tube_onerate' |
    'fedex_10kg_box' |
    'fedex_25kg_box' |
    'fedex_box' |
    'fedex_envelope' | // INTERNATIONAL SAMPLES
    'fedex_extra_large_box' |
    'fedex_large_box' |
    'fedex_medium_box' | // INTERNATIONAL BOXES
    'fedex_pak' |
    'fedex_small_box' | // INTERNATIONAL SMALL BOXES
    'fedex_tube';

export type FedexServiceCode =
    'fedex_international_priority_express' |
    'fedex_ground' |
    'fedex_home_delivery' |
    'fedex_2day' | // DOMESTIC BOXES ALL SIZES
    'fedex_2day_am' |
    'fedex_express_saver' |
    'fedex_standard_overnight' |
    'fedex_priority_overnight' |
    'fedex_first_overnight' |
    'fedex_1_day_freight' |
    'fedex_2_day_freight' |
    'fedex_3_day_freight' |
    'fedex_first_overnight_freight' |
    'fedex_ground_international' |
    'fedex_international_economy' |
    'fedex_international_priority' |
    'fedex_international_first' |
    'fedex_international_economy_freight' |
    'fedex_international_priority_freight' |
    'fedex_international_connect_plus'; // ALL INTERNATIONAL SHIPMENTS

export type DHLPackageCode =
    'package' |
    'dhl_express_wallet_exp_env';

export type DHLServiceCode =
    'dhl_express_worldwide';

export type UPSPackageCode =
    'package' |
    'ups__express_box_large' |
    'ups_10_kg_box' |
    'ups_25_kg_box' |
    'ups_express_box' |
    'ups_express_box_medium' | // NEIGHBORING STATES
    'ups_express_box_small' | // NEIGHBORING STATES
    'ups_express_pak' |
    'ups_letter' |
    'ups_tube';

export type UPSServiceCode = 
    'ups_ground_saver' |
    'ups_ground' | // NEIGHBORING STATES
    'ups_ground_international' |
    'ups_standard_international' |
    'ups_3_day_select' |
    'ups_worldwide_saver' |
    'ups_worldwide_express' |
    'ups_2nd_day_air' |
    'ups_worldwide_expedited' |
    'ups_worldwide_express_plus' |
    'ups_2nd_day_air_am' |
    'ups_next_day_air_saver' |
    'ups_next_day_air' |
    'ups_next_day_air_early_am';

export type PackageCode = StampsPackageCode | FedexPackageCode | DHLPackageCode | UPSPackageCode;
export type ServiceCode = StampsServiceCode | FedexServiceCode | DHLServiceCode | UPSServiceCode;
export type CarrierCode = 'stamps_com' | 'fedex' | 'dhl_express_worldwide' | 'ups_walleted';

export enum DimensionGroup {
    LARGE_BOX,
    SMALL_BOX,
    ENVELOPE
}

export interface IShipment {
    id?: number;
    created_at: string;
    label_data: string;
    shipment_id: number;
    box_group: IRates;
    order_id: number;
    shipment_type: ShipmentType;
    shipment_codes: IShipmentCode;
    printed: boolean;
    shipment_cost: number;
    tracking_number: string;
    tracking_url: string;
}

export enum ShipmentType {
    NEIGHBORING_MEDIUM_BOXES = 'NEIGHBORING_MEDIUM_BOXES',
    NEIGHBORING_SMALL_BOXES = 'NEIGHBORING_SMALL_BOXES',
    NEIGHBORING_SAMPLES = 'NEIGHBORING_SAMPLES',
    DOMESTIC_MEDIUM_BOXES = 'DOMESTIC_MEDIUM_BOXES',
    DOMESTIC_SMALL_BOXES = 'DOMESTIC_SMALL_BOXES',
    DOMESTIC_SAMPLES = 'DOMESTIC_SAMPLES',
    INTERNATIONAL_MEDIUM_BOXES = 'INTERNATIONAL_MEDIUM_BOXES',
    INTERNATIONAL_SMALL_BOXES = 'INTERNATIONAL_SMALL_BOXES',
    INTERNATIONAL_SAMPLES = 'INTERNATIONAL_SAMPLES',
    PO_BOX_SMALL = 'PO_BOX_SMALL',
    PO_BOX_LARGE = 'PO_BOX_LARGE',
    PO_SAMPLES = 'PO_SAMPLES'
}