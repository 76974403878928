import { atom } from "jotai";

export const materialsAtom = atom<IMaterial[]>([]);
export const materialsLoadingAtom = atom<boolean>(false);

export interface IMaterial {
    id?: number;
    brand_id: number;
    category: string;
    material: string;
    application_type: string;
    vendor: string;
    finish: string;
    max_split_length: number;
    overlap_mm: number;
    target_length_ft: number;
    split_length: number;
    jdml_id: number;
    sku_identifier: string;
    jdml_medium: string;
}