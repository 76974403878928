import clsx from "clsx";
import React from 'react';
import { Text } from "../text/Text.component";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export interface GalleryProps {
    onClickItem?: (value: string) => void;
    selectedGalleryItem?: number[] | null;
    useProp?: string;
}

export type GalleryType = GalleryProps & React.ComponentPropsWithoutRef<'div'>;

export const Gallery: React.FunctionComponent<GalleryType> = (props: GalleryType): JSX.Element => {

    const { className, onClickItem, children, selectedGalleryItem, useProp = 'title', ...remaining } = props;

    return (
        <div
            className={
                clsx(className, 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4')
            }
            { ...remaining }
        >
            <div className="contents md:hidden lg:hidden">
                <div className="grid gap-4 auto-rows-min">
                    {
                        React.Children.map(children, (child: React.ReactNode, index: number) => {
                            if (index % 1 !== 0) return null;

                            if (React.isValidElement(child)) {
                                return (
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    <span onClick={ onClickItem ? () => onClickItem(child.props[useProp]) : () => {} }>
                                        { React.cloneElement(child, {selectedGalleryItem, ...child.props}) }
                                    </span>
                                )
                            }
                        })
                    }
                </div>
            </div>
            <div className="hidden md:contents lg:hidden">
                <div className="grid gap-4 auto-rows-min">
                    {
                        React.Children.map(children, (child: React.ReactNode, index: number) => {
                            if (index % 2 !== 0) return null;

                            if (React.isValidElement(child)) {
                                return (
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    <span onClick={ onClickItem ? () => onClickItem(child.props[useProp]) : () => {} }>
                                        { React.cloneElement(child, {selectedGalleryItem, ...child.props}) }
                                    </span>
                                )
                            }
                        })
                    }
                </div>
                <div className="grid gap-4 auto-rows-min">
                    {
                        React.Children.map(children, (child: React.ReactNode, index: number) => {
                            if (index % 2 !== 1) return null;

                            if (React.isValidElement(child)) {
                                return (
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    <span onClick={ onClickItem ? () => onClickItem(child.props[useProp]) : () => {} }>
                                        { React.cloneElement(child, {selectedGalleryItem, ...child.props}) }
                                    </span>
                                )
                            }
                        })
                    }
                </div>
            </div>
            <div className="hidden md:hidden lg:contents">
                <div className="grid gap-4 auto-rows-min">
                    {
                        React.Children.map(children, (child: React.ReactNode, index: number) => {
                            if (index % 3 !== 0) return null;

                            if (React.isValidElement(child)) {
                                return (
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    <span onClick={ onClickItem ? () => onClickItem(child.props[useProp]) : () => {} }>
                                        { React.cloneElement(child, {selectedGalleryItem, ...child.props}) }
                                    </span>
                                )
                            }
                        })
                    }
                </div>
                <div className="grid gap-4 auto-rows-min">
                    {
                        React.Children.map(children, (child: React.ReactNode, index: number) => {
                            if (index % 3 !== 1) return null;

                            if (React.isValidElement(child)) {
                                return (
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    <span onClick={ onClickItem ? () => onClickItem(child.props[useProp]) : () => {} }>
                                        { React.cloneElement(child, {selectedGalleryItem, ...child.props}) }
                                    </span>
                                )
                            }
                        })
                    }
                </div>
                <div className="grid gap-4 auto-rows-min">
                    {
                        React.Children.map(children, (child: React.ReactNode, index: number) => {
                            if (index % 3 !== 2) return null;

                            if (React.isValidElement(child)) {
                                return (
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    <span onClick={ onClickItem ? () => onClickItem(child.props[useProp]) : () => {} }>
                                        { React.cloneElement(child, {selectedGalleryItem, ...child.props}) }
                                    </span>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export interface GalleryItem {
    imageUrl: string;
    title: string;
    galleryItemId: number;
    selectedGalleryItem?: number[] | null;
    showSelectedCheckbox?: boolean;
}

export type GalleryItemType = GalleryItem & React.ComponentPropsWithoutRef<'div'>;

export const GalleryItem: React.FunctionComponent<GalleryItemType> = (props: GalleryItemType): JSX.Element => {

    const { imageUrl, title, galleryItemId, selectedGalleryItem, showSelectedCheckbox = false, ...remaining } = props;

    return (
        <div className="flex flex-col text-center gap-y-2">
            <div
                className={clsx(selectedGalleryItem?.includes(galleryItemId) ? 'border-4 border-slate-800 dark:border-slate-200 rounded-lg' : '', 'relative')}
                { ...remaining }
            >
                <img className="w-full max-w-full rounded-lg" src={ imageUrl } alt={ title } />
                {
                    selectedGalleryItem?.includes(galleryItemId) && showSelectedCheckbox ?
                    <div className="absolute top-0 right-0 w-12 h-12 pt-3 pr-3">
                        <div className="w-9 h-9 bg-green-600 rounded-full shadow-lg">
                            <CheckCircleIcon className="w-8 h-8 pt-[4px] pl-[4px] text-white" />
                        </div>
                    </div> : null
                }
            </div>
            <div className="px-4">
                {
                    title && title !== '' ?
                    <Text> { title } </Text> : null
                }
            </div>
        </div>
    )
}