import { atom } from "jotai";
import { PaginatedResponseType } from "../../globals/types";

export const mockupsAtom = atom<PaginatedResponseType<IMockup>>({ results: [], count: 0, hasNext: false, hasBefore: false });
export const mockupsLoadingAtom = atom<boolean>(false);

export interface IMockup {
    id?: number;
    s3_path: string;
    thumbnail: string;
    mockup_name: string;
    room_type_id: number;
    style_type_id: number;
    color_type_id: number;
}

export interface IUpdateMockupRequest {
    id: number;
    mockup_name: string;
    room_type_id: number;
    style_type_id: number;
    color_type_id: number;
}

export interface IGetMockupsRequest {
    page_count: number;
    page: number;
    queryString: string | null;
}