import { atom } from 'jotai';
import { PaginatedResponseType } from '../../globals/types';

export interface IInvoice {
    id?: number;
    invoice_name: string;
    created_at: string;
    brand_id: number;
    traditional_cost: number;
    traditional_sample_cost: number;
    peel_cost: number;
    peel_sample_cost: number;
    box_cost: number;
    shipping_cost: number;
    sample_shipping_cost: number;
    total_cost: number;
    top_up_cost: number;
    order_id: number;
    charge_status: 'SUCCEEDED' | 'REFUNDED' | 'PENDING' | 'FAILED';
}

export interface GetInvoicesBody {
    brand_id: number;
    page_size: number;
    offset_pages: number;
    querystring: string | null;
}

export const invoicesAtom = atom<PaginatedResponseType<IInvoice>>({ results: [], count: 0, hasNext: false, hasBefore: false });
export const invoicesLoadingAtom = atom<boolean>(false); 
export const singleInvoiceAtom = atom<IInvoice | null>(null);
