import axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { IMaterial, materialsAtom, materialsLoadingAtom } from "./types";

type GetMaterials = (brand_id: number) => Promise<IMaterial[]>;

type MaterialFunctions = {
    get: GetMaterials
}

type UseMaterials = [
    IMaterial[],
    MaterialFunctions
]

export const useMaterials = (): UseMaterials => {

    const [materials, setMaterials] = useAtom(materialsAtom);
    const setMaterialsLoading = useSetAtom(materialsLoadingAtom);
    const [,authToken, loginFunctions] = useLogin();
    const [,setSnackbar] = useSnackbar();

    const getMaterials = async (brand_id: number): Promise<IMaterial[]> => {
        setMaterialsLoading(true);
        const mats: AxiosResponse<IMaterial[]> | void = await axios.get(`${environment.materialsUrl}?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (mats) {
            setMaterials(mats.data);
            setMaterialsLoading(false);
            return mats.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Failed to get materials'
            });
            setMaterials([]);
            setMaterialsLoading(false);
            return [];
        }
    }

    const materialFunctions: MaterialFunctions = {
        get: getMaterials
    }

    return [
        materials,
        materialFunctions
    ];
}