import { useAtom, useSetAtom } from "jotai";
import Axios, { AxiosResponse } from 'axios';
import { useLogin } from "../login";
import { environment } from "../../environment";
import { errorCatcher, snackbarSettingsAtom } from "../../hooks";
import { IJDMLSection, jdmlSectionsAtom, jdmlLoaderAtom } from "./types";

type setJDMLSectionsType = (payload: IJDMLSection[]) => void;
type FetchJDMLSectionsType = (id: number) => void;
type UpdateJDMLSectionType = (body: IJDMLSection) => Promise<IJDMLSection>;

type JDMLSectionKeyFunctions = {
    get: FetchJDMLSectionsType;
    update: UpdateJDMLSectionType;
}

type UseJDMLSections = [
    IJDMLSection[],
    boolean,
    setJDMLSectionsType,
    JDMLSectionKeyFunctions
]

export const useJDMLSections = (): UseJDMLSections => {
    const [jdmlSections, setJDMLSections] = useAtom(jdmlSectionsAtom);
    const [jdmlLoader, setJDMLLoader] = useAtom(jdmlLoaderAtom);
    const [,authToken, loginFunctions] = useLogin();

    const setSnackbar = useSetAtom(snackbarSettingsAtom);

    const getJDMLSections = (id: number) => {
        const fetchJDMLSections = async(setter: setJDMLSectionsType): Promise<void> => {
            setJDMLLoader(true);
            const results = await Axios.get(`${environment.jdmlUrl}/sections?id=${id}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

            if (results) {
                setJDMLLoader(false);
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting the JDML Sections.'
                });
            }
        }
        fetchJDMLSections(setJDMLSections);
    }

    const updateJDMLSection = async(body: IJDMLSection): Promise<IJDMLSection> => {
        const updateResponse: AxiosResponse<IJDMLSection> = await Axios.put(`${environment.jdmlUrl}/section`, body, {
            headers: {
                "Content-Type": "multipart/form-data",
                'X-Wallmates-Auth': authToken
            }
        });
        
        if (updateResponse && updateResponse.data) {
            setJDMLLoader(false);
            setJDMLSections(jdmlSections.map((jdmlSection: IJDMLSection) => {
                if (jdmlSection.id === body.id) {
                    jdmlSection = updateResponse.data
                }
                return jdmlSection;
            }));
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'JDML has been updated.'
            });
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while udpating the JDML Section.'
            });
        }

        return updateResponse.data;
    }

    const keyFunctions: JDMLSectionKeyFunctions = {
        get: getJDMLSections,
        update: updateJDMLSection
    }

    return [
        jdmlSections,
        jdmlLoader,
        setJDMLSections,
        keyFunctions
    ]
}