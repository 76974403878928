import React, { PropsWithChildren } from "react";
import { Button, ButtonColors } from "../button/Button.component";
import clsx from "clsx";
import { Translate } from "../text/Text.component";

export interface CardButton {
    buttonAction: () => void;
    color: ButtonColors;
    text?: string;
    content?: React.ReactElement;
    disabled?: boolean;
}

export interface CardProps {
    cardTitle: string;
    cardType: 'simple' | 'actions' | 'simple-description' | 'description-actions' | 'body-only' | 'simple-double'
    actions?: CardButton[];
    description?: string;
    translateTitle?: boolean;
    translateDescription?: boolean;
    translateButtons?: boolean;
    hasBody?: boolean;
    titleProps?: React.ComponentPropsWithoutRef<'h3'>;
    descriptionProps?: React.ComponentPropsWithoutRef<'h3'>;
    bodyClass?: string;
    titleClass?: string;
}

const CardSection = 'bg-white dark:border-gray-700 dark:bg-stone-900 px-4 py-5 sm:px-6 ';
const CardMain = 'flex flex-col shadow-stone-900/10 dark:shadow-stone-600/15 shadow-lg mb-4 break-words text-gray-900 dark:text-gray-100';

export const Card: React.FunctionComponent<PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>> = (
    props: PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>
): JSX.Element => {

    const { cardType } = props;

    if (cardType === 'actions') {
        return <ActionCard {...props}></ActionCard>
    } else if (cardType === 'simple') {
        return <SimpleCard {...props}></SimpleCard>
    } else if (cardType === 'simple-description') {
        return <SimpleDescriptionCard {...props}></SimpleDescriptionCard>
    } else if (cardType === 'description-actions') {
        return <DescriptionActionsCard {...props}></DescriptionActionsCard>
    } else if (cardType === 'body-only') {
        return <BodyOnlyCard {...props}></BodyOnlyCard>
    } else if (cardType === 'simple-double') {
        return <SimpleDoubleCard {...props}></SimpleDoubleCard>
    }
    
    return (<></>);
}

const ActionCard: React.FunctionComponent<PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>> = (
    props: PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>
): JSX.Element => {

    const { 
        actions, 
        cardTitle, 
        children, 
        translateTitle = true, 
        translateButtons = true, 
        hasBody = true, 
        className, 
        titleProps, 
        bodyClass,
        titleClass
    } = props;
    
    return (
        <div className={clsx(CardMain, className)}>
            <div className={clsx(titleClass ? titleClass + 'px-4 py-5 sm:px-6' : CardSection, hasBody ? 'border-b border-gray-200 rounded-t-lg' : 'rounded-lg')}>
                <div className="-ml-4 -mt-2 w-full flex flex-wrap items-center justify-between sm:flex-nowrap break-words">
                    <div className="ml-4 mt-2 w-full md:w-fit flex flex-row justify-center">
                        {
                            cardTitle !== '' ? 
                            <h3 className={clsx("card-title", titleProps?.className)}>
                                {
                                    translateTitle ?
                                    <Translate translation_key={ cardTitle }></Translate> :
                                    <> { cardTitle } </>
                                }
                            </h3> : null
                        }
                    </div>
                    <div className="ml-4 mt-2 flex flex-row gap-x-3 flex-shrink-0 w-full md:w-fit justify-center pt-2">
                        {
                            actions?.map((button: CardButton) => {
                                return <Button 
                                    key={ button.text || button.color } 
                                    onClick={ button.buttonAction } 
                                    color={ button.color }
                                    disabled={ button.disabled || false }
                                >
                                    {
                                        button.text ? 
                                            (
                                                translateButtons ? 
                                                <Translate translation_key={ button.text } /> :
                                                <> { button.text } </>
                                            ) : (
                                                button.content ? button.content : null
                                            )
                                    }
                                </Button>
                            })
                        }
                    </div>
                </div>
            </div>
            {
                hasBody ?
                <div className={ clsx(bodyClass ? bodyClass + 'px-4 py-5 sm:px-6' : CardSection, 'rounded-b-lg') }>
                    { children }
                </div> : null
            }
        </div>
    )
}

const SimpleDoubleCard: React.FunctionComponent<PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>> = (
    props: PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>
): JSX.Element => {

    const { 
        cardTitle, 
        children, 
        translateTitle = true,
        hasBody = true, 
        className, 
        titleProps, 
        description, 
        translateDescription = true, 
        bodyClass,
        titleClass,
        descriptionProps
    } = props;
    
    return (
        <div className={clsx(CardMain, className)}>
            <div className={clsx(titleClass ? titleClass + 'px-4 py-5 sm:px-6' : CardSection, hasBody ? 'border-b border-gray-200 rounded-t-lg' : 'rounded-lg')}>
                <div className="-ml-4 -mt-2 w-full flex flex-wrap items-center justify-between sm:flex-nowrap break-words">
                    <div className="ml-4 mt-2 w-full md:w-fit flex flex-row justify-center">
                        {
                            cardTitle !== '' ? 
                            <h3 className={clsx("card-title", titleProps?.className)}>
                                {
                                    translateTitle ?
                                    <Translate translation_key={ cardTitle }></Translate> :
                                    <> { cardTitle } </>
                                }
                            </h3> : null
                        }
                    </div>
                    <div className="ml-4 mt-2 flex flex-row gap-x-3 flex-shrink-0 w-full md:w-fit justify-center pt-2">
                        {
                            description !== '' ?
                            <h3 className={clsx("card-title", descriptionProps?.className)}>
                                {
                                    translateDescription ?
                                    <Translate translation_key={ description || '' }></Translate> :
                                    <> { description } </>
                                }
                            </h3> : null
                        }
                    </div>
                </div>
            </div>
            {
                hasBody ?
                <div className={ clsx(bodyClass ? bodyClass + 'px-4 py-5 sm:px-6' : CardSection, 'rounded-b-lg') }>
                    { children }
                </div> : null
            }
        </div>
    )
}

const SimpleCard: React.FunctionComponent<PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>> = (
    props: PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>
): JSX.Element => {

    const { 
        cardTitle, 
        children, 
        translateTitle = true, 
        hasBody = true, 
        className, 
        titleProps, 
        bodyClass,
        titleClass
    } = props;
    
    return (
        <div className={ clsx(CardMain, className) }>
            <div className={ clsx(titleClass ? titleClass + 'px-4 py-5 sm:px-6' : CardSection, hasBody ? 'border-b border-gray-200 rounded-t-lg' : 'rounded-lg') }>
                {
                    cardTitle !== '' ? 
                    <h3 className={clsx("card-title", titleProps?.className)}>
                        {
                            translateTitle ?
                            <Translate translation_key={ cardTitle }></Translate> :
                            <> { cardTitle } </>
                        }
                    </h3> : null
                }
            </div>
            {
                hasBody ?
                <div className={ clsx(bodyClass ? bodyClass + 'px-4 py-5 sm:px-6' : CardSection, 'rounded-b-lg') }>
                    { children }
                </div> : null
            }
        </div>
    )
}

const SimpleDescriptionCard: React.FunctionComponent<PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>> = (
    props: PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>
): JSX.Element => {

    const { 
        cardTitle, 
        description, 
        children, 
        translateTitle = true, 
        translateDescription = true, 
        hasBody = true, 
        className, 
        titleProps, 
        bodyClass,
        titleClass,
        descriptionProps
    } = props;
    
    return (
        <div className={ clsx(CardMain, className) }>
            <div className={ clsx(titleClass ? titleClass + 'px-4 py-5 sm:px-6' : CardSection, hasBody ? 'border-b border-gray-200 rounded-t-lg' : 'rounded-lg') }>
                {
                    cardTitle !== '' ?
                    <h3 className={clsx("card-title", titleProps?.className)}>
                        {
                            translateTitle ?
                            <Translate translation_key={ cardTitle }></Translate> :
                            <> { cardTitle } </>
                        }
                    </h3> : null
                }
                {
                    description !== '' ?
                    <h3 className={clsx("card-title", descriptionProps?.className)}>
                        {
                            translateDescription ?
                            <Translate translation_key={ description || '' }></Translate> :
                            <> { description } </>
                        }
                    </h3> : null
                }
            </div>
            {
                hasBody ? 
                <div className={ clsx(bodyClass ? bodyClass + 'px-4 py-5 sm:px-6' : CardSection, 'rounded-b-lg') }>
                    { children }
                </div> : null
            }
        </div>
    )
}

const DescriptionActionsCard: React.FunctionComponent<PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>> = (
    props: PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>
): JSX.Element => {

    const { 
        actions, 
        cardTitle, 
        description, 
        children, 
        translateTitle = true, 
        translateDescription = true, 
        translateButtons = true, 
        hasBody = true, 
        className,
        descriptionProps,
        titleProps, 
        bodyClass,
        titleClass
    } = props;
    
    return (
        <div className={ clsx(CardMain, className) }>
            <div className={ clsx(titleClass ? titleClass + 'px-4 py-5 sm:px-6' : CardSection, hasBody ? 'border-b border-gray-200 rounded-t-lg' : 'rounded-lg') }>
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                        {
                            cardTitle !== '' ?
                            <h3 className={clsx("card-title", titleProps?.className)}>
                                {
                                    translateTitle ?
                                    <Translate translation_key={ cardTitle }></Translate> :
                                    <> { cardTitle } </>
                                }
                            </h3> : null
                        }
                        {
                            description !== '' ?
                            <h3 className={clsx("card-title", descriptionProps?.className)}>
                                {
                                    translateDescription ?
                                    <Translate translation_key={ description || '' }></Translate> :
                                    <> { description } </>
                                }
                            </h3> : null
                        }
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        {
                            actions?.map((button: CardButton) => {
                                return <Button 
                                    key={ button.text || button.color } 
                                    onClick={ button.buttonAction } 
                                    color={ button.color }
                                    disabled={ button.disabled || false }
                                >
                                    {
                                        button.text ? 
                                            (
                                                translateButtons ? 
                                                <Translate translation_key={ button.text } /> :
                                                <> { button.text } </>
                                            ) : (
                                                button.content ? button.content : null
                                            )
                                    }
                                </Button>
                            })
                        }
                    </div>
                </div>
            </div>
            {
                hasBody ?
                <div className={ clsx(bodyClass ? bodyClass + 'px-4 py-5 sm:px-6' : CardSection, 'rounded-b-lg') }>
                    { children }
                </div> : null
            }
        </div>
    )
}

const BodyOnlyCard: React.FunctionComponent<PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>> = (
    props: PropsWithChildren<CardProps> & React.ComponentPropsWithoutRef<'div'>
): JSX.Element => {

    const { children, className, bodyClass } = props;
    
    return (
        <div className={ clsx(CardMain, className) }>
            <div className={ clsx(bodyClass ? bodyClass + 'px-4 py-5 sm:px-6' : CardSection, 'rounded-lg') }>
                { children }
            </div>
        </div>
    )
}