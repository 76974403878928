import { atom } from "jotai";
import { PaginatedResponseType } from "../../globals/types";

export const jdmlAtom = atom<IJDMLType[]>([]);
export const dropperGroupAtom = atom<IDropperGroup | null>(null);
export const dropperGroupLoadingAtom = atom<boolean>(false);
export const dropGroupsAtom = atom<PaginatedResponseType<IPaginatedDropGroup>>({ results: [], count: 0, hasBefore: false, hasNext: false } as PaginatedResponseType<IPaginatedDropGroup>);
export const dropGroupsLoadingAtom = atom<boolean>(false);

export const jdmlSectionsAtom = atom<IJDMLSection[]>([]);
export const jdmlLoaderAtom = atom<boolean>(false);

export interface IJDMLType {
    id?: number;
    name: string;
}

export interface IDropperGroup {
    totalReadyForPrint: number;
    totalReadyInGroup: number;
    lineItemsInGroup: number[];
}

export interface IDropGroup {
    id?: number;
    created_at?: string;
    line_items: {
        items: number[];
        reprints: number[];
    }
    user: number;
    brand_id: number;
    material_id: number;
}

export interface IPaginatedDropGroup {
    id: number;
    created_at: string;
    line_items: {
        items: number[];
        reprints: number[];
    };
    user_id: number;
    user_first: string;
    user_last: string;
    material_name: string;
    brand_name: string;
    brand_id: number;
}

export interface IGetDropGroupsRequest {
    page_count: number;
    page: number;
    queryString: string | null;
}

export interface IJDMLSection {
    id?: number;
    jdml_id: number;
    jdml_section_type_id: number;
    jdml_content: string;
    name?: string;
    isSection?: boolean;
    isEdited?: boolean;
}