import { atom } from "jotai";
import { ICreateDesignRequest, IApplyPatternRequest, ICreateSampleRequest, ICreateHeaderRequest } from "../product";

export const notificationsAtom = atom<INotification[]>([]);
export const notificationsLoadingAtom = atom<boolean>(false);

export interface INotification {
    id: number;
    created_at: string;
    passthrough_data: ICreateDesignRequest | IApplyPatternRequest | ICreateSampleRequest | ICreateHeaderRequest;
    category: 'MOCKUP_UPLOAD' | 'SAMPLE_CREATION' | 'PRODUCT_UPLOAD' | 'HEADER_CREATION';
    status_message: string;
    status: 'unprocessed' | 'processing' | 'processed' | 'failed';
    brand_id: number;
}
