import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-jsx';
import 'prismjs/themes/prism.css';

export interface CodeEditorProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  style?: React.CSSProperties;
  validation?: boolean
}

export const CodeEditor: React.FunctionComponent<CodeEditorProps> = (props: CodeEditorProps) => {
  const { value, onChange, style, className, validation } = props;
  const [metadataError, setMetadataError] = React.useState<string | null>(null);

  const handleChange = (value: string) => {
    if (validation) {
      try {
        JSON.parse(value);
        setMetadataError(null);
      } catch (error: any) {
        setMetadataError(error.message || error || 'Invalid JSON');
      }
      onChange(value);
    }
  }

  return (
    <Editor
      value={value}
      onValueChange={handleChange}
      highlight={(code: string) => highlight(code, languages.javascript, 'javascript')}
      padding={10}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 12,
        border: '1px solid #d1d5db',
        borderRadius: 5,
        ...style,
      }}
      className={`${className} ${metadataError ? '!border-red-500' : ''}`}
    />
  );
}