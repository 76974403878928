import Axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { PaginatedResponseType } from "../../globals/types";
import { 
    IMockup, 
    IGetMockupsRequest, 
    IUpdateMockupRequest, 
    mockupsAtom, 
    mockupsLoadingAtom 
} from "./types";

type SetMockupType = (payload: PaginatedResponseType<IMockup>) => void;
type FetchMockupsType = (query: IGetMockupsRequest) => void;
type DeleteMockupType = (mockup_id: number) => Promise<boolean>;
type AddMockupType = (form: FormData) => Promise<IMockup | null>;
type UpdateMockupType = (body: IUpdateMockupRequest) => Promise<IMockup | null>;

type MockupKeyFunctions = {
    get: FetchMockupsType;
    delete: DeleteMockupType;
    add: AddMockupType;
    update: UpdateMockupType;
}

type UseMockups = [
    PaginatedResponseType<IMockup>,
    SetMockupType,
    MockupKeyFunctions
];

export const useMockups = (): UseMockups => {
    const [mockups, setMockups] = useAtom(mockupsAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();
    const setMockupsLoading = useSetAtom(mockupsLoadingAtom);

    const getMockups = (body: IGetMockupsRequest) => {
        const fetchMockups = async (setter: SetMockupType): Promise<void> => {
            setMockupsLoading(true);
            const results = await Axios.get(`${environment.mockupsUrl}?page_count=${body.page_count}&page=${body.page}${body.queryString?.length ? `&queryString=${body.queryString}` : ''}`, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

            if (results) {
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting mockups. Please try again later.'
                })
            }
            setMockupsLoading(false);
        }
        // send and forget
        fetchMockups(setMockups);
    }

    const add = async (form: FormData): Promise<IMockup | null> => {
        const insertResponse: AxiosResponse<{result: IMockup}> | void = await Axios.post(environment.insertMockupUrl, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (insertResponse) {
            mockups.results = [...mockups.results, insertResponse.data.result];
            setMockups(mockups);
            return insertResponse.data.result;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while adding a new mockup. Please try again later.'
            })
            return null;
        }
    }

    const deleteMock = async (mockupId: number): Promise<boolean> => {
        const deleteResponse: AxiosResponse<boolean> | void = await Axios.delete(`${environment.mockupsUrl}?mockup_id=${mockupId}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (deleteResponse) {
            const resetMockups = mockups;
            resetMockups.results = mockups.results.filter((item: IMockup) => item.id !== mockupId);
            setMockups(resetMockups);
            return deleteResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while deleting a mockup. Please try again later.'
            })
            return false;
        }
    }

    const updateMockup = async (body: IUpdateMockupRequest): Promise<IMockup | null> => {
        const updateResponse: AxiosResponse<IMockup> | void = await Axios.put(`${environment.mockupsUrl}`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (updateResponse) {
            const resetMockups = mockups;
            resetMockups.results = [...mockups.results.map((mockup: IMockup) => {
                if (mockup.id === body.id) {
                    return updateResponse.data;
                }
                return mockup
            })];
            setMockups(resetMockups);
            return updateResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while updating a mockup. Please try again later.'
            })
            return null;
        }
    }

    const keyFunctions: MockupKeyFunctions = {
        add: add,
        delete: deleteMock,
        get: getMockups,
        update: updateMockup
    }

    return [
        mockups,
        setMockups,
        keyFunctions
    ]
}

