import { atom, useAtom } from "jotai";
import { environment } from "../environment";


export const socketAtom = atom<WebSocket | null>(null);
export const socketWeightMessage = atom<{[key: string]: any} | null>(null);
export const socketScannerMessage = atom<{[key: string]: any} | null>(null);

type SocketFunctions = {
    sendMessage: (data: string) => void;
    connect: () => void;
}

type UseSocket = [
    WebSocket | null,
    SocketFunctions,
    {[key: string]: any} | null,
    {[key: string]: any} | null,
];

export const useSocket = (): UseSocket => {
    const [socket, setSocket] = useAtom(socketAtom);
    const [weightMessage, setSocketWeightMessage] = useAtom(socketWeightMessage);
    const [scannerMessage, setSocketScannerMessage] = useAtom(socketScannerMessage);

    const connect = async () => {
        const ws = new WebSocket(`${environment.socketUrl}`);
        ws.onclose = () => {
            setSocket(null);
        }
        ws.onmessage = (event: MessageEvent<any>) => {
            const data = JSON.parse(event.data);
            if (data.type === "weight") {
                setSocketWeightMessage(data);
            } else if (data.type === "scanner") {
                setSocketScannerMessage(data);
            }
        }
        setSocket(ws);
    }

    const sendMessage = async (data: string) => {
        const ws = new WebSocket(`${environment.socketUrl}`);
        ws.onopen = () => {
            ws.send(data);
            ws.close();
        }
    }

    const socketFunctions: SocketFunctions = {
        sendMessage,
        connect
    }

    return [
        socket,
        socketFunctions,
        weightMessage,
        scannerMessage
    ]
}