import axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { PaginatedResponseType } from "../../globals/types";
import { 
    IGetOrdersQuery, 
    IOrder, 
    IFailedOrderQueue, 
    IOrderDTO, 
    ordersAtom, 
    ordersCountAtom, 
    ordersLastQueryAtom, 
    failedOrderQueueItemsAtom, 
    ordersLoadingAtom, 
    failedOrderQueueItemsLoadingAtom, 
    singleOrderAtom, 
    singleOrderLoadingAtom 
} from "./types";

type GetOrders = (query: IGetOrdersQuery) => Promise<PaginatedResponseType<IOrder> | null>;
type GetOrderById = (id: number) => IOrder;
type GetOrderCount = () => number;
type GetLastQuery = () => IGetOrdersQuery | null;
type GetFailedOrderQueueItems = (brand_id: number) => Promise<IFailedOrderQueue[] | null>;
type GetFailedOrderQueueItemById = (id: number) => IFailedOrderQueue;
type GetOrder = (id: number) => Promise<IOrderDTO | null>;
type CancelOrder = (order_id: number) => Promise<null>;

type UseOrdersKeyFunctions = {
    get: GetOrders,
    getById: GetOrderById,
    getOrderCount: GetOrderCount,
    getLastQuery: GetLastQuery,
    getFailedOrderQueueItems: GetFailedOrderQueueItems,
    getFailedOrderQueueItemById: GetFailedOrderQueueItemById,
    getOrder: GetOrder,
    cancelOrder: CancelOrder
}

type UseOrder = [
    PaginatedResponseType<IOrder>,
    IOrderDTO | null,
    IFailedOrderQueue[],
    UseOrdersKeyFunctions
];

export const useOrder = (): UseOrder => {
    const [orders, setOrders] = useAtom(ordersAtom);
    const [orderCount, setOrderCount] = useAtom(ordersCountAtom);
    const [lastQuery, setLastQuery] = useAtom(ordersLastQueryAtom);
    const [failedOrderQueueItems, setFailedOrderQueueItems] = useAtom(failedOrderQueueItemsAtom);
    const setOrdersLoading = useSetAtom(ordersLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();
    const setFailedQueueLoading = useSetAtom(failedOrderQueueItemsLoadingAtom);

    const [singleOrder, setSingleOrder] = useAtom(singleOrderAtom);
    const setSingleOrderLoading = useSetAtom(singleOrderLoadingAtom);   

    const getOrders: GetOrders = async (query: IGetOrdersQuery): Promise<PaginatedResponseType<IOrder> | null> => {
        setOrdersLoading(true);
        setLastQuery(query);
        const getResponse: AxiosResponse<PaginatedResponseType<IOrder>> | void = await axios.get(`${environment.ordersUrl}?brand_id=${query.brand_id}&page_count=${query.page_count}&page=${query.page}${query.filter_by !== undefined ? `&filter_by=${query.filter_by}` : ''}${query.queryString ? `&queryString=${query.queryString}` : ''}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (getResponse) {
            setOrders(getResponse.data);
            setOrderCount(getResponse.data.count);
            setOrdersLoading(false);
            return getResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting orders. Please try again later.'
            })
            setOrdersLoading(false);
            return null;
        }
    }

    const cancelOrder = async (order_id: number): Promise<null> => {
        setSingleOrderLoading(true);
        const cancelOrderResponse: AxiosResponse<null> | void = await axios.post(`${environment.ordersUrl}/order-cancel`, { order_id }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (cancelOrderResponse) {
            setSingleOrderLoading(false);
            return null;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting failed order queue items. Please try again later.'
            });
            setSingleOrderLoading(false);
            return null;
        }
    }

    const getById: GetOrderById = (id: number): IOrder => {
        return orders.results.find((value: IOrder) => value.id === id) as IOrder;
    }

    const getFailedOrderQueueItems = async (brand_id: number): Promise<IFailedOrderQueue[] | null> => {
        setFailedQueueLoading(true);
        const getResponse: AxiosResponse<IFailedOrderQueue[]> | void = await axios.get(`${environment.queuesUrl}/order-processor?brand_id=${brand_id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (getResponse) {
            setFailedOrderQueueItems(getResponse.data);
            setFailedQueueLoading(false);
            return getResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting failed order queue items. Please try again later.'
            });
            setFailedQueueLoading(false);
            return null;
        }
    }

    const getFailedOrderQueueItemById = (id: number): IFailedOrderQueue => {
        return failedOrderQueueItems.find((item: IFailedOrderQueue) => item.order_queue_item.id === id) as IFailedOrderQueue;
    }

    const getOrder = async (id: number): Promise<IOrderDTO | null> => {
        setSingleOrderLoading(true);
        const getResponse: AxiosResponse<IOrderDTO> | void = await axios.get(`${environment.ordersUrl}/${id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (getResponse) {
            setSingleOrderLoading(true);
            setSingleOrder(getResponse.data);
            return getResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting failed order queue items. Please try again later.'
            });
            setSingleOrderLoading(false);
            return null;
        }
    }

    const keyFunctions: UseOrdersKeyFunctions = {
        get: getOrders,
        getById,
        getOrderCount: () => orderCount,
        getLastQuery: () => lastQuery,
        getFailedOrderQueueItems,
        getFailedOrderQueueItemById,
        getOrder,
        cancelOrder
    }

    return [
        orders,
        singleOrder,
        failedOrderQueueItems,
        keyFunctions
    ]
}