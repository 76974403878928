import { useAtom, useSetAtom } from "jotai";
import { errorCatcher, useSnackbar, socketWeightMessage } from "../../hooks";
import { useLogin } from "../login";
import axios, { AxiosResponse } from "axios";
import { environment } from "../../environment";
import { IPrinter } from "../printers";
import { 
    IWeighQueue, 
    IWeightScanResponse, 
    weightScanItemLoadingAtom, 
    weighQueueAtom 
} from "./types";

type ScanWeightItem = (query: IWeighQueue, printer: IPrinter) => Promise<IWeightScanResponse | null>;
type GetWeighQueue = () => Promise<IWeighQueue[]>;
type DeleteActiveBox = (activeWeight: IWeighQueue) => Promise<IWeighQueue | null>;

type UseWeightScannerKeyFunctions = {
    scan: ScanWeightItem;
    getWeighQueue: GetWeighQueue;
    deleteActiveBox: DeleteActiveBox;
}

type UseWeightScanner = [
    IWeighQueue[],
    UseWeightScannerKeyFunctions
];

export const useWeighScanner = (): UseWeightScanner => {
    const [, authToken, loginFunctions] = useLogin();
    const [,setSnackbar] = useSnackbar();
    const setScanLoading = useSetAtom(weightScanItemLoadingAtom);
    const [weighQueue, setWeighQueue] = useAtom(weighQueueAtom);
    const [, setSocketWeightMessage] = useAtom(socketWeightMessage);

    const scanItem = async (query: IWeighQueue, printer: IPrinter): Promise<IWeighQueue | null> => {
        setScanLoading(true);
        const weighQueue: AxiosResponse<IWeighQueue> | void = await axios.post(`${environment.scanUrl}/update-weight`, { weighQueue: query, printer }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (weighQueue) {
            setScanLoading(false);
            return weighQueue.data;
        } else {
            setScanLoading(false);
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while performing scan'
            })
            return null;
        }
    }

    const getWeighQueue = async (): Promise<IWeighQueue[]> => {
        const weighQueue: AxiosResponse<IWeighQueue[]> | void = await axios.get(`${environment.scanUrl}/weight-queue`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (weighQueue) {
            setWeighQueue(weighQueue.data);
            setSocketWeightMessage({
                "type": "weight",
                "device_turned_on": false,
                "weight": Math.round(Math.random() * 100) / 100,
                "device_plugged_in": false,
                "weight_type": 'Unknown'
            })
            return weighQueue.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error getting the weigh queue'
            })
            return [];
        }
    }

    const deleteActiveBox: DeleteActiveBox = async (activeWeight: IWeighQueue): Promise<IWeighQueue | null> => {
        const weighQueue: AxiosResponse<IWeighQueue> | void = await axios.delete(`${environment.scanUrl}/weight-queue?id=${activeWeight.id}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (weighQueue) {
            return weighQueue.data;      
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error deleting the weigh queue'
            })
            return null;
        }
    }

    const scanKeyFunctions: UseWeightScannerKeyFunctions = {
        scan: scanItem,
        getWeighQueue,
        deleteActiveBox
    }

    return [
        weighQueue,
        scanKeyFunctions
    ]
}