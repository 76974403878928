import { atom } from "jotai";

export const brandsAtom = atom<IBrand[]>([]);
export const brandsLoadingAtom = atom<boolean>(false);
export const loggedInBrandAtom = atom<IBrand | null>(null);
export const singleBrandAtom = atom<IBrand | null>(null);
export const singleBrandLoadingAtom = atom<boolean>(false);

export interface IBrand {
    id?: number;
    name: string;
    shopify_brand_name: string;
    top_up_amount?: number;
    waiting_payment?: boolean;
    static_header_rpe?: string;
    brand_logo?: string;
    shopify_api_key?: string;
    shopify_api_secret?: string;
    shopify_access_token?: string;
    production_enabled?: boolean;
}

export interface IUploadLogoRequest {
    id?: number;
    name: string;
    shopify_brand_name: string;
    logo: string;
}