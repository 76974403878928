import { atom } from "jotai";
import { PaginatedResponseType } from "../../globals/types";
import { IInvoice } from "../invoices";

export const ordersAtom = atom<PaginatedResponseType<IOrder>>({results: [], count: 0, hasNext: false, hasBefore: false});
export const ordersLoadingAtom = atom<boolean>(false);
export const ordersCountAtom = atom<number>(0);
export const ordersLastQueryAtom = atom<IGetOrdersQuery | null>(null);
export const orderLoadingAtom = atom<boolean>(false);
export const failedOrderQueueItemsAtom = atom<IFailedOrderQueue[]>([]);
export const failedOrderQueueItemsLoadingAtom = atom<boolean>(false);
export const singleOrderAtom = atom<IOrderDTO | null>(null);
export const singleOrderLoadingAtom = atom<boolean>(false);

export interface IOrder {
    id: number;
    order_number: number;
    order_total: number;
    order_date: string;
    brand_id: number;
    order_status: string;
    fulfillment_status: string;
    customer_first: string;
    customer_last: string;
    jdml: string;
}

export interface IFailedOrderQueue {
    order_queue_item: IOrderQueue;
    line_items: IFailedOrderQueueLineItem[];
}

export interface IOrderQueue {
    id?: number;
    created_at?: string;
    order_number: number;
    process_status: 'unprocessed' | 'processing' | 'processed';
    brand_id: number;
    status_message: string;
    environment: string;
}

export interface IFailedOrderQueueLineItem {
    design_name: string;
    has_design: boolean;
    has_design_metadata: boolean;
    image_url: string;
}

export enum IFulfillmentStatus {
    SUCCESS = 'SUCCESS',
    CANCELLED = 'CANCELLED',
    FULFILLED = 'FULFILLED',
    PARTIAL = 'PARTIAL',
    UNFULFILLED = 'UNFULFILLED'
}

export enum IOrderStatus {
    NEW = 'NEW',
    PAST_DUE = 'PAST DUE',
    ISSUE = 'ISSUE',
    FULFILLED = 'FULFILLED',
    UNFULFILLED = 'UNFULFILLED',
    PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED'
}

export interface IGetOrdersQuery {
    page_count: number;
    page: number;
    queryString: string | null;
    brand_id: number;
    filter_by?: string;
}

export interface IOrderDTO {
    id: number;
    order_number: number;
    status: {
        name: string;
    }
    fulfillment_status: {
        name: string;
    }
    order_total: number;
    customer: {
        id: number;
        customer_id: number;
        first_name: string;
        last_name: string;
        shipping_first_name: string;
        shipping_last_name: string;
        addresses: {
            id: number;
            address_type: number;
            street_address: string;
            city: string;
            state: string;
            zip: string;
            country: string;
        }[]
    }
    order_notes: string;
    payment_method: string;
    discount: number;
    brand: {
        id: number;
        name: string;
    }
    status_update: string;
    order_date: string;
    metadata: {
        jdml: string;
    }[];
    invoices: IInvoice[];
}