import { useAtom, useSetAtom } from "jotai";
import Axios from 'axios';
import { environment } from "../../environment";
import { errorCatcher, snackbarSettingsAtom } from "../../hooks";
import { useLogin } from "../login";
import { IFont, fontsAtom } from "./types";

type SetFontType = (payload: IFont[]) => void;
type FetchFontsType = () => void;
type FontKeyFunctions = {
    get: FetchFontsType;
}

type UseFonts = [
    IFont[],
    SetFontType,
    FontKeyFunctions
];

export const useFonts = (): UseFonts => {
    const [fonts, setFonts] = useAtom(fontsAtom);
    const [,authToken, loginFunctions] = useLogin();

    const setSnackbar = useSetAtom(snackbarSettingsAtom);

    const getFonts = () => {
        const fetchFonts = async (setter: SetFontType): Promise<void> => {
            const results = await Axios.get(environment.fontsUrl, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (results) {
                setter(results.data);
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while getting brands. Please try again later.'
                })
            }
        }

        fetchFonts(setFonts);
    }

    const keyFunctions: FontKeyFunctions = {
        get: getFonts
    }

    return [
        fonts,
        setFonts,
        keyFunctions
    ]
}