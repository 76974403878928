import { atom } from "jotai";

export const artTypeAtom = atom<IArtType[]>([]);

export interface IArtType {
    id?: number;
    symbol: string;
    type_name: string;
    max_height: number;
    max_width: number;
    allow_scale: boolean;
    quantity_limit: boolean;
    description: string;
}