import { CompletedMultipartUpload } from "aws-sdk/clients/s3";
import { atom } from "jotai";

const localUser = localStorage.getItem('user');
const user = localUser === null ? null : JSON.parse(localUser) as IUser;
export const loggedInUserAtom = atom<IUser | null>(user);

const localToken = localStorage.getItem('authToken');
const authToken = localToken === null ? null : localToken;
export const authTokenAtom = atom<string | null>(authToken);

export const usersAtom = atom<IUser[]>([]);
export const usersAtomLoading = atom<boolean>(false);
export const permissionsAtom = atom<IPermission[]>([]);

export interface ILoginRequest {
    email: string;
    password: string;
}

export interface ILoginResponse {
    user: IUser | null;
    authToken: string | null;
}

export type PresignType = 'headObject' | 'getObject' | 'putObject' | 'createMultipart' | 'uploadPart' | 'completeMultipart';

export interface IAWSPresignerRequest {
    type: PresignType;
    bucket: string;
    key: string;
    uploadId?: string;
    size?: number;
    mimetype?: string;
    partNumber?: number;
    multipart?: CompletedMultipartUpload;
}

export interface IAWSPresignedURL {
    url: string;
}

export interface ISendPresignedRequest {
    type: PresignType;
    url: string;
    body?: ArrayBuffer;
    bucket?: string;
    key?: string;
    uploadId?: string;
    multipart?: CompletedMultipartUpload;
}

export interface ICreateUserRequest {
    id: number;
    verify_token: string;
    first: string;
    last: string;
    password: string;
}

export interface ICreateAccountRequest {
    brand_id: number;
    is_new_brand: boolean;
    brand: {name: string};
    first: string;
    last: string;
    email: string;
    password: string;
    permission: number;
}

export interface IVerifyUserRequest {
    user_id: number;
    verify_token: string;
}

export interface IUpdateUserRequest {
    user_id: number;
    permission: number;
}

export interface ICreateNewUserRequest {
    email?: string;
    first?: string;
    last?: string;
    password?: string;
    is_new_brand: boolean;
    brand: {
        id: number;
        name?: string;
    },
    permission: number;
}

export interface IForgotPasswordRequest {
    email?: string;
    send_reset_email: boolean;
    id?: number;
    reset_token?: string;
    password?: string;
}

export interface IUser {
    id?: number;
    brand_id: number;
    first: string;
    last: string;
    email: string;
    email_verified: boolean;
    password?: string;
    salt?: string;
    permissions?: IUserPermission;
    temporary_token?: string;
}

export interface IUserPermission {
    id?: number;
    permission_id: number | IPermission;
    created_at: string;
    user_id: number;
}

export interface IPermission {
    id?: number;
    created_at: string;
    permission: 'USER' | 'ADMIN' | 'TECHNICIAN' | 'SUPERUSER' | 'SUPERADMIN';
    display_name: string;
    is_publicly_visible: boolean;
}